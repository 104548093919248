import React, { useState } from "react";
import axios from "axios";
import "../css/Project.css";
import apiUrl from "../config";
import { useNavigate } from "react-router-dom";

const ProjectForm = () => {
  const [error, setError] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [data, setData] = useState({
    heading: "",
    para: "",
    state: "",
    city: "",
    BuildingArea: "",
    ProjectStatus: "Ongoing",
    images: [],
  });
  const navigate = useNavigate();
  const States = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const handleStateChange = (event) => {
    const state = event.target.value;
 
    setData({ ...data, state });

    setError((prevError) => {
      const newError = { ...prevError };
      if (newError.state) {
        delete newError.state;
      }
      return newError;
    });
  };
  

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });

    setError((prevError) => {
      const newError = { ...prevError };
      if (newError[name]) {
        delete newError[name];
      }
      return newError;
    });
  };
  

  const validateForm = () => {
    const errors = {};
    if (data.heading.length < 5) {
      errors.heading = "Heading must be at least 5 characters long.";
    }
    if (!data.BuildingArea) {
      errors.BuildingArea = "Building Area is required.";
    }
    if (!data.city) {
      errors.city = "City is required.";
    }
    if (!data.state) {
      errors.state = "State is required.";
    }
    if (selectedImages.length < 3) {
      errors.images = "Please add at least 3 images.";
    }
    return errors;
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => ({
      url: URL.createObjectURL(file),
      name: file.name,
      file,
    }));

    setSelectedImages((previousImages) => {
      const newImages = [...previousImages, ...imagesArray];
      return newImages.slice(0, 3);
    });

    setData((prevData) => ({
      ...prevData,
      images: [...prevData.images, ...imagesArray].slice(0, 3),
    }));

    setError((prevError) => {
      const newError = { ...prevError };
      if (newError.images && selectedFiles.length <3) {
        delete newError.images;
      }
      return newError;
    });
    event.target.value = "";
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    } else {
      setError({});
    }

    try {
      const formData = new FormData();
      formData.append("heading", data.heading);
      formData.append("para", data.para);
      formData.append("state", data.state);
      formData.append("city", data.city);
      formData.append("BuildingArea", data.BuildingArea);
      formData.append("ProjectStatus", data.ProjectStatus);
      data.images.forEach((image, index) => {
        formData.append(`images`, image.file);
      });

      const response = await axios.post(
        apiUrl + "upload/addproject",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        navigate("/projectList");
      }
      setData({
        heading: "",
        para: "",
        state: "",
        city: "",
        BuildingArea: "",
        ProjectStatus: "Ongoing",
        images: [],
      });
      setSelectedImages([]);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
    }
  };

  const deleteHandler = (image) => {
    setSelectedImages(selectedImages.filter((e) => e.url !== image.url));
    URL.revokeObjectURL(image.url);
    setData({
      ...data,
      images: data.images.filter((img) => img.url !== image.url),
    });
  };

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="images_Container">
          <div className="main-Header text-center my-4">
            <h2 className="font-weight-bold text-dark text-center">
              Add New Project
            </h2>
            <p className="text-muted" style={{ fontSize: "15px" }}>
              Whether you're looking to transform your home, office, or a
              specialized space, Vedic Architectures is ready to embark on this
              design journey with you.
            </p>
          </div>

          <div className="formsContent mx-auto" style={{ maxWidth: "1000px" }}>
            <form onSubmit={submitHandler}>
              <div className="row mt-3 justify-content-center">
                <div className="col-lg-8 col-md-10 col-sm-12 userName-field">
                  <div className="form-group ">
                    <label>
                      Project Heading <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Project Heading"
                      name="heading"
                      value={data.heading}
                      onChange={changeHandler}
                    />
                    {error.heading && (
                      <p className="text-danger">{error.heading}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row  mt-2 justify-content-center">
                <div className="form-group col-lg-8 col-md-10 col-sm-12">
                  <label>Project Description</label>
                  <textarea
                    className="form-control"
                    placeholder="Project Description"
                    name="para"
                    value={data.para}
                    onChange={changeHandler}
                  />
                </div>
              </div>

              <div className="row mt-3 justify-content-center">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className="form-group">
                    <label>
                      Building Area <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="BuildingArea"
                      value={data.BuildingArea}
                      onChange={changeHandler}
                      placeholder="Building Area"
                    />
                    {error.BuildingArea && (
                      <p className="text-danger">{error.BuildingArea}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className="form-group">
                    <label className="">
                      Project Status <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="ProjectStatus"
                      value={data.ProjectStatus}
                      onChange={changeHandler}
                    >
                      <option value="Ongoing">Ongoing</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row mt-3 justify-content-center">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className="form-group">
                    <label>
                      State <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="state"
                      value={data.state}
                      onChange={handleStateChange}
                    >
                      <option value="">Select State</option>
                      {States.map((state) => (
                        <option key={state} value={state} >
                          {state}
                        </option>
                      ))}
                    </select>
                    {error.state && (
                      <p className="text-danger">{error.state}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className="form-group">
                    <label>
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City Name"
                      name="city"
                      value={data.city}
                      onChange={changeHandler}
                    />
                    {error.city && <p className="text-danger">{error.city}</p>}
                  </div>
                </div>
              </div>

              <div className="form-group mt-3 files-btn">
                <label>
                  Add images <span className="text-danger">*</span>
                </label>
                <br />
                <button
                  style={{
                    background: "#CE308226",
                    color: "#CE3082",
                    border: "none",
                    padding: "5px",
                  }}
                  type="button"
                  onClick={() => document.getElementById("fileInput").click()}
                  disabled={selectedImages.length >= 3}
                >
                  Choose Files
                </button>
                <input
                  id="fileInput"
                  className="d-none"
                  type="file"
                  name="images"
                  onChange={onSelectFile}
                  multiple
                  accept="image/png, image/jpeg, image/webp"
                />
                <div className="images mt-2 d-flex justify-content-center flex-wrap">
                  {selectedImages &&
                    selectedImages.map((image) => (
                      <div
                        key={image.url}
                        className="position-relative d-inline-block m-2"
                      >
                        <img
                          src={image.url}
                          height="150"
                          width="150"
                          alt="upload"
                        />
                        <p className="text-center mt-2">{image.name}</p>
                        <button
                          onClick={() => deleteHandler(image)}
                          className="position-absolute btn btn-danger"
                          style={{
                            top: "1px",
                            right: "1px",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50%",
                          }}
                        >
                          <span
                            style={{
                              position: "relative",
                              top: "-7px",
                              left: "-4px",
                            }}
                          >
                            X
                          </span>
                        </button>
                      </div>
                    ))}
                </div>
                {error.images && (
                  <p className="text-danger text-center">{error.images}</p>
                )}
              </div>

              <div className="text-center mt-3 mb-3">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Submit"
                  style={{
                    backgroundColor: "#CE3082",
                    color: "#F8FAFC",
                    border: "none",
                  }}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectForm;
