import React from "react";
import "../css/ourprocess.css";
const OurProcess = () => {
  return (
    <>
      <div className="container-fluid">
        <div className=" ourprocess-container">
          <div className="ourprocess-scroll-part-main ">
            <div className="ourprocess-scrolling-div">
              <div className="number-client-core-execute-main-div-one">
                <div className="ourprocess-number-image">
                  <img
                    className="ourprocess-number-fixed-image"
                    src="../images/01.png"
                    alt="not found"
                  />
                </div>

                <div className="client-core-execute-div">
                  <h1 className="client-core-execute-heading">
                    CLIENT DISCUSSION
                  </h1>
                  <img
                    className="ourprocess-images"
                    src="../images/client-discussion.png"
                    alt="not found"
                  />
                  <p className="client-core-execute-para">
                    We start by talking with you. Your insights and vision guide
                    us.Share your thoughts, preferences, and dreams for your
                    space. We're here to listen.
                  </p>
                </div>
              </div>
              <div className="number-client-core-execute-main-div-two">
                <div className="ourprocess-number-image">
                  <img
                    className="ourprocess-number-fixed-image"
                    src="../images/02.png"
                    alt="not found"
                  />
                </div>
                <div className="client-core-execute-div">
                  <h1 className="client-core-execute-heading">
                    CONCEPTUAL DESIGN
                  </h1>
                  <img
                    className="ourprocess-images"
                    src="../images/ConceptualDesign.png"
                    alt="not found"
                  />
                  <p className="client-core-execute-para">
                    Next, we create a Conceptual Design based on our
                    discussions. We'll provide visual representations like
                    sketches and mood boards, ensuring our design aligns with
                    your expectations.{" "}
                  </p>
                  <div
                    className="mainOne Residential_MainContent"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="row">
                      <div className="residentila_container col-12">
                        <h4>Residential</h4>
                        <p style={{ color: "#767676" }}>
                          We start by talking with you. Your insights and vision
                          guide us. Share your thoughts, preferences, and dreams
                          for your space. We're here to listen.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mainTwo MainContent_rowOne">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <img
                          src="../images/villa.png"
                          style={{
                            height: "300px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="Villas"
                        />
                        <p
                          style={{
                            textAlign: "center",
                            color: "#767676",
                            fontSize: "18px",
                            marginTop: "5px",
                          }}
                        >
                          Villas
                        </p>
                      </div>
                      <div className="col-md-6 col-12">
                        <img
                          src="../images/Residential-Apartment.png"
                          style={{
                            height: "300px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="Apartments"
                        />
                        <p
                          style={{
                            textAlign: "center",
                            color: "#767676",
                            fontSize: "18px",
                            marginTop: "5px",
                          }}
                        >
                          Apartments
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Commercial Section */}
                  <div
                    className="Commercial_MainContent"
                    style={{ marginTop: "30px" }}
                  >
                    <div className="row">
                      <div className="col-12 commercial_content">
                        <h4 style={{ color: "#232321" }}>Commercial</h4>
                        <p style={{ color: "#767676" }}>
                          We start by talking with you. Your insights and vision
                          guide us. Share your thoughts, preferences, and dreams
                          for your space. We're here to listen.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="Commercial_ContentOne_row">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <img
                          src="../images/Restaurants.png"
                          style={{
                            height: "300px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="Restaurants"
                        />
                        <p
                          style={{
                            textAlign: "center",
                            color: "#767676",
                            fontSize: "18px",
                          }}
                        >
                          Restaurants
                        </p>
                      </div>
                      <div className="col-md-6 col-12">
                        <img
                          src="../images/ITOffices.png"
                          style={{
                            height: "300px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="IT Offices"
                        />
                        <p
                          style={{
                            textAlign: "center",
                            color: "#767676",
                            fontSize: "18px",
                          }}
                        >
                          IT Offices
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="Commercial_ContentTwo_row">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <img
                          src="../images/CoWorkingSpaces.png"
                          style={{
                            height: "300px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="Co Working Spaces"
                        />
                        <p
                          style={{
                            textAlign: "center",
                            color: "#767676",
                            fontSize: "18px",
                          }}
                        >
                          Co Working Spaces
                        </p>
                      </div>
                      <div className="col-md-6 col-12">
                        <img
                          src="../images/Hotels.png"
                          style={{
                            height: "300px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          alt="Hotels"
                        />
                        <p
                          style={{
                            textAlign: "center",
                            color: "#767676",
                            fontSize: "18px",
                          }}
                        >
                          Hotels
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row Commercial_ContentThree_row">
                    <div className="col-md-6 col-12">
                      <img
                        src="../images/Apartments.png"
                        style={{
                          height: "300px",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt="Apartments"
                      />
                      <p
                        style={{
                          textAlign: "center",
                          color: "#767676",
                          fontSize: "18px",
                        }}
                      >
                        Apartments
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="number-client-core-execute-main-div-three ">
                <div className="ourprocess-number-image">
                  <img
                    className="ourprocess-number-fixed-image"
                    src="../images/03.png"
                    alt="not found"
                  />
                </div>
                <div className="client-core-execute-div">
                  <h1 className="client-core-execute-heading">
                    IMPLEMENTATION
                  </h1>
                  <img
                    className="ourprocess-images"
                    src="../images/execute.png"
                    alt="not found"
                  />
                  <p className="client-core-execute-para">
                    Once we've nailed down the perfect concept, it's time to
                    bring it to life. Our team meticulously plans and executes
                    the design, turning it into a tangible, beautiful space for
                    you to enjoy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProcess;
