import React, { useEffect, useState } from "react";
import "../css/portifolio.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import SwiperNavButton from "./SwiperNavButton";
import axios from "axios";
import apiUrl from "../config";
const Portifolio = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [projects, setProjects] = useState([]);


  const GetPortfolios = async () => {
    try {
      const res = await axios.get(apiUrl + "upload/getportifolios");

      setProjects(res.data.portfolios);
    } catch (error) {
      console.error("Error fetching portfolios:", error);
    }
  };
  useEffect(() => {
    GetPortfolios();
  }, []);


  const PortifolioSlide = ({ project, index }) => {
    let defaultImage = "";
    let parsedImages = [];

    try {
      parsedImages = JSON.parse(project.images || "[]");
      defaultImage = parsedImages[0] || "";
    } catch (error) {
      console.error("Error parsing project images:", error);
    }

    const [bigImg, setBigImg] = useState(defaultImage);
    const [selectedImg, setSelectedImg] = useState(defaultImage);

    const handleClick = (newImg) => {
      setBigImg(newImg);
      setSelectedImg(newImg);
    };
    return (
      <div className="container-fluid portfolio-main-container">
        <div className="">
          <div className="portifolio-main">
            <div className="portifolio-all-imgs">
              <div className="portifolio-blur-img-container-div">
                <div>
                  <img
                    src={bigImg}
                    className="portifolio-big-img"
                    alt="not found"
                  />
                </div>
                <div className="portifolio-blur-container">
                  <div className="portifolio-comtemporary-discover-div">
                    <h1 className="portifolio-comtemporary">
                      {project.heading}
                    </h1>
                    <p className="portifolio-discover">{project.para}</p>
                  </div>
                  <div className="portifolio-blur-inside-container">
                    <p className="portifolio-location-build-proj">
                      <span>Location:</span>
                      <label className="portifolio-location-build-proj-right">
                       {project.city}, {project.state}
                      </label>
                    </p>
                    <p className="portifolio-location-build-proj">
                      {" "}
                      <span>Building Area :</span>
                      <label className="portifolio-location-build-proj-right">
                        {project.BuildingArea}
                      </label>
                    </p>
                    <p className="portifolio-location-build-proj">
                      {" "}
                      <span>Project Status : </span>
                      <label className="portifolio-location-build-proj-right">
                        {project.ProjectStatus}
                      </label>
                    </p>
                  </div>
                </div>
              </div>
              <div className="portifolio-imgs-div">
                {JSON.parse(project.images)?.map((imgUrl, imgIndex) => (
                  <img
                    key={imgIndex}
                    src={imgUrl}
                    className={
                      selectedImg === imgUrl
                        ? "portifolio-right-img selected"
                        : "portifolio-right-img"
                    }
                    onClick={() => handleClick(imgUrl)}
                    alt={`Project ${index + 1}  ${imgIndex + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="container-fluid portfolio-main-container">
        <div className="">
          <h1 className="portifolio-heading text-center">OUR PROJECTS</h1>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          >
            {projects.map((project, index) => (
              <SwiperSlide key={index}>
                <PortifolioSlide project={project} index={index} />
              </SwiperSlide>
            ))}
            <div className="pagenation-btn">
              <SwiperNavButton
                projects={projects.map((val) => val.project)}
                activeIndex={activeIndex}
              />
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Portifolio;
