import React, { useState, useEffect } from "react";
import "../css/PortfolioDisplay.css";
import axios from "axios";
import apiUrl from "../config";
import { MdCancel } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button } from "react-bootstrap";

const PortfolioDisplay = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [formData, setFormData] = useState({});
  const [imageFiles, setImageFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [showImages, setShowImges] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [imageError, setImageError] = useState("");

  const fetchPortfolios = async () => {
    try {
      const response = await axios.get(apiUrl + "upload/getportifolios");
      if (response.status === 200) {
        setPortfolios(response.data.portfolios);
        setLoading(false);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };



  const States = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  useEffect(() => {
    fetchPortfolios();
  }, []);

  useEffect(() => {
    if (selectedPortfolio) {
      setFormData({
        heading: selectedPortfolio.heading,
        para: selectedPortfolio.para,
        state: selectedPortfolio.state,
        city: selectedPortfolio.city,
        BuildingArea: selectedPortfolio.BuildingArea,
        ProjectStatus: selectedPortfolio.ProjectStatus,
      });

      const images =
        typeof selectedPortfolio.images === "string"
          ? JSON.parse(selectedPortfolio.images)
          : selectedPortfolio.images;
      setPreviewImages(images || []);
      setImageFiles([]);
      setImagesToDelete([]);
      setImageError("");
    }
  }, [selectedPortfolio]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.post(apiUrl + `upload/delete/${id}`);
      if (response.status === 200) {
        fetchPortfolios();
        setError("");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleUpdate = (portfolio) => {
    setSelectedPortfolio(portfolio);
  };
  const handleStateChange = (e) => {
    setFormData((prevData) => ({ ...prevData, state: e.target.value }));
  };

  const closeModal = () => {
    setSelectedPortfolio(null);
    setPreviewImages([]);
    setImagesToDelete([]);
    setImageError("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + previewImages.length !== 3) {
      setImageError("You can upload up to 3 images only.");
    } else {
      const newPreviewImages = files.map((file) => URL.createObjectURL(file));
      setImageFiles((prevFiles) => [...prevFiles, ...files]);
      setShowImges([...newPreviewImages]);
      setImageError("");
    }
  };

  const handleImageDelete = (index) => {
    setPreviewImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImageFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (previewImages.length + imageFiles.length !== 3) {
      setImageError(
        `You need to upload ${
          3 - (previewImages.length + imageFiles.length)
        } more images.`
      );
      return;
    }

    if (selectedPortfolio) {
      const formDataToSend = new FormData();
      formDataToSend.append("heading", formData.heading);
      formDataToSend.append("para", formData.para);
      formDataToSend.append("state", formData.state);
      formDataToSend.append("city", formData.city);
      formDataToSend.append("BuildingArea", formData.BuildingArea);
      formDataToSend.append("ProjectStatus", formData.ProjectStatus);
      imageFiles.forEach((file) => {
        formDataToSend.append("images", file);
      });
      formDataToSend.append("previewImages", [previewImages]);

      try {
        const response = await axios.post(
          apiUrl + `upload/update/${selectedPortfolio.id}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          setError("");
          closeModal();
          setShowImges([]);
          fetchPortfolios();
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };
  return (
    <div>
      {loading && <p>Loading...</p>}
      <div className="portfolio-container">
        {portfolios.map((portfolio) => (
          <div key={portfolio.id} className="portfolio-item">
            <h2>{portfolio.heading}</h2>
            <p>{portfolio.para}</p>
            <p>
              {portfolio.state}, {portfolio.city}
            </p>
            <p>Building Area: {portfolio.BuildingArea}sq.ft</p>
            <p>Status: {portfolio.ProjectStatus}</p>
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {portfolio.images &&
                JSON.parse(portfolio.images)?.map((imageUrl, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={imageUrl}
                      alt={`Portfolio ${portfolio.id}  ${index + 1}`}
                      className="portfolio-image"
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
            <div className="portfolio-buttons">
              <button onClick={() => handleUpdate(portfolio)}>Update</button>
              <button onClick={() => handleDelete(portfolio.id)}>Delete</button>
            </div>
          </div>
        ))}

        <Modal show={selectedPortfolio !== null} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Portfolio</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="heading">Heading:</label>
                <input
                  type="text"
                  id="heading"
                  className="form-control"
                  name="heading"
                  value={formData.heading || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="para">Description:</label>
                <textarea
                  id="para"
                  name="para"
                  className="form-control"
                  value={formData.para || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="state">State:</label>
                <select
                  id="state"
                  className="form-control"
                  name="state"
                  value={formData.state || ""}
                  onChange={handleStateChange}
                  required
                >
                  <option value="">Select a State</option>
                  {States.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="city">City:</label>
                <input
                  type="text"
                  id="city"
                  className="form-control"
                  name="city"
                  value={formData.city || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="BuildingArea">Building Area:</label>
                <input
                  type="text"
                  id="BuildingArea"
                  className="form-control"
                  name="BuildingArea"
                  value={formData.BuildingArea || ""}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="ProjectStatus">Status:</label>
                <select
                  className="form-control"
                  name="ProjectStatus"
                  value={formData.ProjectStatus || ""}
                  onChange={handleInputChange}
                  required
                >
                  <option value="Ongoing">Ongoing</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="images">Upload Images:</label>
                {imageError && <p className="error">{imageError}</p>}
                <input
                  className="form-control"
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  multiple
                  onChange={handleImageChange}
                />

                <div className="preview-images">
                  {previewImages.map((image, index) => (
                    <div key={index} className="preview-image-container">
                      <img
                        src={image}
                        alt={`Preview ${index + 1}`}
                        className="preview-image"
                      />
                      <MdCancel
                        onClick={() => handleImageDelete(index)}
                        className="delete-button"
                      />
                    </div>
                  ))}
                  {showImages.map((image, index) => (
                    <div key={index} className="preview-image-container-1">
                      <img
                        src={image}
                        alt={`Preview ${index + 1}`}
                        className="preview-image"
                      />
                      <MdCancel
                        onClick={() => handleImageDelete(index)}
                        className="delete-button"
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-buttons">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
                <Button variant="secondary" onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default PortfolioDisplay;
