import React from 'react';
import "../css/footer.css";
import { SlSocialInstagram } from "react-icons/sl";
import { SiTwitter } from "react-icons/si";
import { FaFacebook } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='container-fluid Footer-main'>
      <div className='container'>
        <div className='Footer-main'>
          <div className='Footer-left'>
            <img src='../images/vedic-logo.png' className='footer-vedic-logo' alt="Vedic Logo" />
            <h1 className='footer-left-heading'>Transform your space with our comprehensive interior design services. From contemporary to classic styles, we specialize in creating personalized and stunning interiors that reflect your unique taste and lifestyle.</h1>
          </div>

          <div className='Footer-right'>
            <div className='Footer-right-left-ul'>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item footer-quick'>Quick Links</li>
                <li className='footer-li-items'><Link className='footer-li-items' to="/"> Home</Link></li>
                <li className='footer-li-items'><Link className='footer-li-items' to="/about-us"> About us</Link></li>
                <li className='footer-li-items'><Link className='footer-li-items' to="/portfolio"> Portfolio</Link></li>
                {/* <li className='footer-li-items'><Link className='footer-li-items' to="/"> Contact us</Link></li> */}
              </ul>
            </div>
            <div className='Footer-right-right-ul footer-right-items'>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item'>Phone</li>
                <li className='footer-li-items'><a href="tel:+919000050948" style={{ textDecoration: "none", color: "#ffffff" }}>+91-9000050948</a></li>
              </ul>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item'>Email</li>
                <li className='footer-li-items'><a href='mailto:info@vedicarchitects.in' style={{ textDecoration: "none", color: "#ffffff" }}>info@vedicarchitects.in</a></li>
              </ul>
              <ul className='footer-ul-items'>
                <li className='quick-link-li-item'>Contact Info</li>
                <li className='footer-li-items address'>303B, Capital Park, Madhapur, Telangana Hyd-500081</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='bottom-div'>
          <div className='bottom-line'></div>
          <div className='footer-bottom-container'>
            <p className='footer-copyright-para'>© Copyright 2024, All Rights Reserved by AbilioIt Solutions</p>
            <div className='footer-icons-container'>
              <a href="https://twitter.com/?lang=en-in" target="_blank" rel="noopener noreferrer">
                <SiTwitter style={{ color: '#1DA1F2', fontSize: '1.5rem' }} />
              </a>
              <a href='https://www.facebook.com/' target="_blank" rel="noopener noreferrer">
                <FaFacebook style={{ color: '#1877F2', fontSize: '1.5rem' }} />
              </a>
              <a href="https://www.instagram.com/?hl=en" target="_blank" rel="noopener noreferrer">
                <SlSocialInstagram style={{ color: '#E4405F', fontSize: '1.5rem' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
