import React from "react";
import "../css/testimonial.css";
const Testimonials = () => {
  return (
    <div className="container-fluid mt-5">
      <div className="container">
        <div className="testimonial-main-container row">
          <div className="testimonial-top-container col-lg-06 col-sm-12 col-md-06">
            <h1 className="testimonial-main-heading">TESTIMONIAL</h1>
            <h1 className="testimonial-heading">What Our Customers Say</h1>
            <p className="testimonial-para">
              Discover the perfect blend of innovation and elegance.
            </p>
          </div>

          <div className="testimonial-body-container  col-lg-06 col-sm-12 col-md-06">
            <div className="testimonial-body-left-container">
              <img
                src="../images/inverted-comma.svg"
                className="testimonial-inverted-comma-img"
              />
              <p className="testimonial-body-para pt-4">
                We would like to express our utmost satisfaction with the
                service provided by Vedic
              </p>
            </div>
            <div className="test">
              <div className="testimonial-body-right-container">
                <div className="card testimonial-card">
                  <img
                    src="../images/reviews-1.jpg"
                    className="card-img-top"
                    alt="..."
                  />

                  <p className="card-text testimonial-discover">
                    Experience the seamless integration of cutting-edge design
                    and timeless sophistication.
                  </p>
                  <h5 className="card-title testimonial-contemporary">
                    CONTEMPORARY LUXURY VILLA
                  </h5>
                </div>
                <div className="card testimonial-card">
                  <img
                    src="../images/reviews-2.jpg"
                    className="card-img-top"
                    alt="..."
                  />

                  <p className="card-text testimonial-discover">
                    Our new office space, designed by Vedic Architects, has
                    exceeded our expectations.
                  </p>
                  <h5 className="card-title testimonial-contemporary">
                    Modern Office Space
                  </h5>
                </div>
                <div className="card testimonial-card">
                  <img
                    src="../images/reviews-3.jpg"
                    className="card-img-top"
                    alt="..."
                  />

                  <p className="card-text testimonial-discover">
                    Our new home designed by Vedic Architects is everything we
                    hoped for and more.
                  </p>
                  <h5 className="card-title testimonial-contemporary">
                    Contemporary Family Home
                  </h5>
                </div>
                {/* <div className="card testimonial-card" >
                                <img src="../images/testimonial.png" className="card-img-top" alt="..." />
                                <p className="card-text testimonial-discover">Discover the perfect blend of innovation <br />and elegance</p>
                                <h5 className="card-title testimonial-contemporary">CONTEMPORARY LUXURY VILLA</h5>
                            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
