import React from 'react';
import { useSwiper } from 'swiper/react';
import "../css/portifolio.css"

const SwiperNavButton = ({ projects, activeIndex }) => {
    const swiper = useSwiper();

    const slidePrev = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    const slideNext = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    const isPrevButtonActive = activeIndex > 0;
    const isNextButtonActive = activeIndex < projects.length - 1;
    return (
        <div className='container-fluid '>
            <div className='container portifolio-swiper-nav-btn'>
                <div className="swiper-nav-btns" >
                    <div className='left-btn'>
                        <button className='left-nav-swiper-button' onClick={slidePrev} disabled={!isPrevButtonActive}>Previous Project</button>
                    </div>
                    <p className='swiper-project-name'>Project-{activeIndex+1}</p>
                    <div className='right-btn'>
                        <button className='right-nav-swiper-button' onClick={slideNext} disabled={!isNextButtonActive}>Next Project</button>
                    </div>
                </div>
            </div>
        </div>);
};

export default SwiperNavButton;
