import React from 'react'
import Projects from './Projects'
import Collection from './Collection'
import Testimonials from './Testimonials'
import Footer from '../components/Footer'
import Portifolio from './Portifolio'
import OurProcess from './OurProcess'
import Header from './Header'



const Home = () => {

  return (
    <div className=''>
      <div className=''>
        <Header />
        <OurProcess />
        <Projects />
        <Collection />
        <Testimonials />
      </div>
    </div>
  )
}

export default Home