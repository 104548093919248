import React, { useEffect, useState } from "react";
import "../css/aboutus.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import apiUrl from "../config";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    Email: "",
    Enquiry: "",
    Location: "",
    projects: "",
    DesksRequired: "",
  });

  const [errors, setErrors] = useState({});

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await axios.post(apiUrl + `contact`, details, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setDetails({
          firstName: "",
          lastName: "",
          phoneNumber: "",
          Email: "",
          Enquiry: "",
          Location: "",
          projects: "",
          DesksRequired: "",
        });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!details.firstName.trim())
      newErrors.firstName = "First Name is required";
    if (!details.phoneNumber.trim())
      newErrors.phoneNumber = "Phone Number is required";
    if (!details.Enquiry.trim()) newErrors.Enquiry = "Enquiry is required";
    if (!details.Location.trim()) newErrors.Location = "Location is required";
    if (!details.lastName.trim()) newErrors.lastName = "Last Name is required";
    if (!details.Email.trim()) newErrors.Email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(details.Email))
      newErrors.Email = "Invalid email address";
    if (!details.projects.trim())
      newErrors.projects = "Project information is required";
    if (!details.DesksRequired.trim())
      newErrors.DesksRequired = "Desks Required is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollToContact) {
      const contactSection = document.getElementById("contactus");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="aboutus-container-main">
            <div className="aboutus-main">
              <div className="aboutus-top-main">
                <h1 className="aboutus-top-main-heading">ABOUT US</h1>
                <div className="aboutus-top-inner row">
                  <div className="aboutus-top-left">
                    <h2 className="aboutus-top-left-heading">
                      Vedic Architectures & Interiors
                    </h2>
                    <p className="aboutus-top-left-para1">
                      Discover the perfect blend of innovation and elegance. Our
                      architects and designers craft spaces that tell your
                      unique story. From concept to creation, let us transform
                      your vision into reality.
                    </p>
                    <p className="aboutus-top-left-para2">
                      Experience the fusion of cutting-edge design and timeless
                      sophistication with Vedic Architects & Interiors. Our team
                      transforms your ideas into stunning, functional spaces
                      that reflect your individuality from start to finish.
                    </p>
                  </div>
                  <div className="aboutus-top-right">
                    <img
                      className="aboutus-top-right-image"
                      src="../images/aboutus-header-image.svg"
                      alt="About Us Header"
                    />
                  </div>
                </div>
              </div>
              <div className="aboutus-keyElements-container">
                <h2 className="aboutus-keyElements-heading">
                  Key Elements of Vedic Architectures
                </h2>
                <div className="aboutus-keyElements-bottom-container">
                  <div className="aboutus-keyElements-bottom-main row">
                    <div className="col-md-4 col-sm-12">
                      <img
                        className="aboutus-keyElements-images"
                        src="../images/vastu-image.svg"
                        alt="Vastu Shastra"
                      />
                      <h5 className="aboutus-keyElements-bottom-main-heading-text">
                        Vastu Shastra
                      </h5>
                      <p className="aboutus-keyElements-bottom-main-para">
                        Our designs incorporate Vastu Shastra, an ancient Indian
                        science of architecture, which focuses on the harmonious
                        arrangement of elements to enhance positive energy flow
                        within a space.
                      </p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <img
                        className="aboutus-keyElements-images"
                        src="../images/Natural-image.svg"
                        alt="Natural Elements"
                      />
                      <h5 className="aboutus-keyElements-bottom-main-heading-text">
                        Natural Elements
                      </h5>
                      <p className="aboutus-keyElements-bottom-main-para">
                        We embrace the elements of nature, integrating natural
                        light, ventilation, and sustainable materials to create
                        spaces that are not only visually pleasing but also
                        contribute to the well-being of the occupants.
                      </p>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <img
                        className="aboutus-keyElements-images"
                        src="../images/sacred-image.svg"
                        alt="Sacred Geometry"
                      />
                      <h5 className="aboutus-keyElements-bottom-main-heading-text">
                        Sacred Geometry
                      </h5>
                      <p className="aboutus-keyElements-bottom-main-para">
                        Vedic Architectures often incorporates sacred geometry,
                        which is believed to bring a sense of order and harmony
                        to the built environment. The precision in design
                        reflects the cosmic order found in the universe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="whatWedo_mainContainer"
                style={{ fontSize: "14px" }}
              >
                <div className="row">
                  <div className="col-6 col-md-6 whatWedo_imageContainer">
                    <img
                      src="../images/client-discussion.png"
                      alt="Vedic Home"
                    />
                  </div>
                  <div className="col-12 col-md-6 whatWedo_textContainer">
                    <h4 className="fw-bold">What We Do</h4>

                    <p>
                      At Vedic Architectures, we specialize in creating
                      harmonious and functional spaces inspired by ancient Vedic
                      principles. Our services cover a wide range of
                      architectural and design needs, ensuring that every
                      project we undertake is a true reflection of our clients'
                      visions and lifestyles.
                    </p>
                    <p>Our Key Services:</p>
                    <ul>
                      <li>
                        Architectural Design: Innovative and elegant solutions
                        that blend form and function.
                      </li>
                      <li>
                        Interior Design: Aesthetically pleasing and practical
                        interiors for better living.
                      </li>
                      <li>
                        Estimations: Accurate cost assessments for informed
                        decision-making.
                      </li>
                      <li>
                        Renovations: Expert renovations to transform and
                        revitalize your space.
                      </li>
                      <li>
                        Space Planning: Optimized layouts for maximum comfort
                        and efficiency.
                      </li>
                      <li>
                        Sustainable Design: Eco-friendly materials and practices
                        for responsible designs.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="whoWeAre_mainContainer "
                style={{
                  width: "100%",
                  margin: "auto",
                  color: "#767676",
                  fontSize: "14px",
                }}
              >
                <div className="row">
                  <div className="col-12 col-md-6 who-we-are-text">
                    <h4 className="fw-bold who-we-are-title">Who We Are</h4>
                    <p>
                      Vedic Architectures is a team of passionate architects and
                      designers dedicated to combining ancient wisdom with
                      modern design. Founded on Vedic principles, we aim to
                      create spaces that promote balance, harmony, and
                      well-being.
                    </p>
                    <p>Our Story:</p>
                    <p>
                      Inspired by Vedic texts, our founders established Vedic
                      Architectures to offer a holistic approach to design.
                      Since we’ve been dedicated to transforming spaces with
                      respect for cultural heritage and a commitment to
                      excellence.
                    </p>
                  </div>
                  <div className="col-12 col-md-6 who-we-are-image-container">
                    <img
                      className="who-we-are-image"
                      src="../images/client-discussion.png"
                      alt="Vedic Home"
                    />
                  </div>
                </div>
              </div>

              {/* Our Team */}
              <div>
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      margin: "auto",
                      color: "#767676",
                      marginTop: "50px",
                    }}
                  >
                    <div
                      style={{
                        margin: "auto",
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <h4 className="fw-bold" style={{ color: "#232321" }}>
                        Our Team
                      </h4>
                    </div>
                    <p>
                      Our diverse team includes architects, interior designers,
                      project managers, and craftsmen. We believe in
                      collaboration, innovation, and attention to detail to
                      deliver unique and thoughtful designs.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="ourTeams_Container"
                style={{ color: "#767676", fontSize: "14px" }}
              >
                <div className="row">
                  <div
                    className="col-md-4 d-flex flex-column align-items-center text-center"
                    style={{ lineHeight: "18px" }}
                  >
                    <div style={{ width: "80%", margin: "20px auto" }}>
                      <div
                        className="rounded-circle bg-secondary d-flex justify-content-center align-items-center"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "auto",
                        }}
                      ></div>
                      <h4 className="fw-bold mt-3" style={{ color: "#000000" }}>
                        Suresh Royal
                      </h4>
                      <span>Founder, Vedic Architect</span>
                      <p className="mt-2">
                        As the founder and chief Vedic architect of our design
                        firm, I integrate time-honored Vedic Architect
                        principles with cutting-edge design techniques to create
                        spaces that harmonize tradition and innovation.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-4 d-flex flex-column align-items-center text-center"
                    style={{ lineHeight: "18px" }}
                  >
                    <div style={{ width: "80%", margin: "20px auto" }}>
                      <div
                        className="rounded-circle bg-secondary d-flex justify-content-center align-items-center"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "auto",
                        }}
                      ></div>
                      <h4 className="fw-bold mt-3" style={{ color: "#000000" }}>
                        J.Veni
                      </h4>
                      <span>Founder, Vedic Architect</span>
                      <p className="mt-2">
                        As the pioneering founder and Vedic architect of our
                        team, I design spaces that embody the ancient wisdom of
                        Vastu Shastra, creating harmonious environments that
                        nurture tranquility and success.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-md-4 d-flex flex-column align-items-center text-center"
                    style={{ lineHeight: "18px" }}
                  >
                    <div style={{ width: "80%", margin: "20px auto" }}>
                      <div
                        className="rounded-circle bg-secondary d-flex justify-content-center align-items-center"
                        style={{
                          height: "80px",
                          width: "80px",
                          margin: "auto",
                        }}
                      ></div>
                      <h4 className="fw-bold mt-3" style={{ color: "#000000" }}>
                        k.Jagadeesh
                      </h4>
                      <span>Founder, Vedic Architect</span>
                      <p className="mt-2">
                        Fueled by a dedication to Vedic Architect, I launched
                        our company to enhance well-being and equilibrium
                        through expertly designed spaces that respect both
                        ancient traditions and modern aesthetics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <div
                    className="col-6"
                    style={{
                      margin: "auto",
                      color: "#767676",
                      marginTop: "50px",
                    }}
                  >
                    <div>
                      <h2 className="aboutus-top-main-heading">Enquiry Form</h2>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="contactus-main-inner-bottom-form row">
                        <div className="contactus-main-inner-bottom-form-left col-md-6 ">
                          <div className="contactus-main-inner-bottom-form-field for mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              First Name
                              <span className="contactus-star">*</span>
                            </label>
                            <div>
                              <input
                                type="text"
                                name="firstName"
                                value={details.firstName}
                                placeholder="First Name"
                                onChange={handleFormChange}
                                id=" contactus-desks-div-input "
                                className={
                                  errors.firstName ? "error" : "input-ele"
                                }
                              />
                            </div>
                          </div>
                          <div className="contactus-main-inner-bottom-form-field for mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Phone Number
                              <span className="contactus-star">*</span>
                            </label>
                            <input
                              value={details.phoneNumber}
                              placeholder="Enter your Mobile Number"
                              name="phoneNumber"
                              onChange={handleFormChange}
                              onKeyDown={(e) => {
                                if (
                                  !(
                                    e.key === "Backspace" || e.key === "Delete"
                                  ) &&
                                  !/[\d]/.test(e.key)
                                ) {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                              className={
                                errors.phoneNumber ? "error" : "input-ele"
                              }
                            />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Enquiry for
                              <span className="contactus-star">*</span>
                            </label>
                            <input
                              className={errors.Enquiry ? "error" : "input-ele"}
                              type="text"
                              name="Enquiry"
                              value={details.Enquiry}
                              placeholder="Enquiry"
                              onChange={handleFormChange}
                            />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Location*
                            </label>
                            <input
                              className={
                                errors.Location ? "error" : "input-ele"
                              }
                              id=" contactus-desks-div-input "
                              type="text"
                              name="Location"
                              value={details.Location}
                              placeholder="Location"
                              onChange={handleFormChange}
                            />
                          </div>
                        </div>
                        <div className="contactus-main-inner-bottom-form-right col-md-6 ">
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Last Name<span className="contactus-star">*</span>
                            </label>
                            <input
                              className={
                                errors.lastName ? "error" : "input-ele"
                              }
                              type="text"
                              name="lastName"
                              value={details.lastName}
                              placeholder="Last Name"
                              onChange={handleFormChange}
                            />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Email<span className="contactus-star">*</span>
                            </label>
                            <input
                              className={errors.Email ? "error" : "input-ele"}
                              type="text"
                              name="Email"
                              value={details.Email}
                              placeholder=" Email"
                              onChange={handleFormChange}
                            />
                          </div>
                          <div className="contactus-main-inner-bottom-form-field mb-3">
                            <label
                              htmlFor="exampleInputPassword1"
                              className="form-label"
                            >
                              Project’s per sq.ft
                            </label>
                            <input
                              className={
                                errors.projects ? "error" : "input-ele"
                              }
                              type="text"
                              name="projects"
                              value={details.projects}
                              placeholder="Project’s per sq.ft"
                              onChange={handleFormChange}
                            />
                          </div>
                        </div>
                        <div className="contactus-desks-div mb-3">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label"
                          >
                            Desks Required
                          </label>
                          <div className=" ">
                            <textarea
                              id="desk"
                              className={
                                errors.DesksRequired ? "error" : "input-ele"
                              }
                              type="text"
                              name="DesksRequired"
                              value={details.DesksRequired}
                              placeholder="How desks required for workspace."
                              onChange={handleFormChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="contactus-submit-btn-div">
                        <button
                          type="submit"
                          className="contactus-submit-btn"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
